import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { linkResolver } from '../utils/linkResolver'

export const query = graphql`
  {
    prismic {
      allLessons {
        edges {
          node {
            title
            lesson_type
            _meta {
              id
              uid
              type
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const lessons = data.prismic.allLessons.edges
  const recipes = lessons.filter(lesson => lesson.node.lesson_type === 'Rezepte')
  const techniques = lessons.filter(lesson => lesson.node.lesson_type === 'Techniken')
  const tools = lessons.filter(lesson => lesson.node.lesson_type === 'Utensilien')
  const ingredients = lessons.filter(lesson => lesson.node.lesson_type === 'Zutaten')

  const LessonPosts = ({ lessons }) => {
    if (!lessons) return null
    return (
      <div className="pt-3 pb-16 md:border-t md:border-gray-200">
        {lessons.map(({node: lesson}, index) => {
          return (
            <div key={index} className="">
              <Link to={linkResolver(lesson._meta)} className="text-lg text-canblue">
                {lesson.title}
              </Link>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <Layout title="Verzeichnis">
      <div className="py-8">
        <div className="px-6 py-8 max-w-2xl mx-auto">
          <h1 className="mt-20 font-display text-6xl">Verzeichnis</h1>
          <p className="mt-8 text-xl max-w-xl leading-relaxed font-display md:mt-6 lg:mt-8">
            Schneller Zugriff auf alle Rezepte. <span className="border-b border-canred">Erhalte eine Nachricht</span> wenn wir neue Rezepte online stellen. 
          </p>
          <div className="mt-12 md:mt-20 grid grid-cols-1 sm:grid-cols-2">
            <h3 className="pt-4 font-caps text-sm uppercase tracking-widest md:border-t md:border-gray-200">Rezepte</h3>
            <LessonPosts lessons={recipes} />
            <h3 className="pt-4 font-caps text-sm uppercase tracking-widest md:border-t md:border-gray-200">Techniken</h3>
            <LessonPosts lessons={techniques} />

            <h3 className="pt-4 font-caps text-sm uppercase tracking-widest md:border-t md:border-gray-200">Utensilien</h3>
            <LessonPosts lessons={tools} />

            <h3 className="pt-4 font-caps text-sm uppercase tracking-widest md:border-t md:border-gray-200">Zutaten</h3>
            <LessonPosts lessons={ingredients} />
          </div>
        </div>
      </div>
    </Layout>
  )
}